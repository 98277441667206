import "./Project.scss";
import { ProjectInterface } from "../../assets/projectInfo";
import { BiRightArrow } from "react-icons/bi";
import { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Button from "../Button/Button";

interface ProjectExtras extends ProjectInterface {
  darkMode: boolean;
  showImages: string;
  setShowImages: React.Dispatch<React.SetStateAction<string>>;
}

const Project = ({
  name,
  description,
  date,
  details,
  imgs,
  tech,
  darkMode,
  mobileImgs,
  showImages,
  setShowImages,
}: ProjectExtras) => {
  const titleFont = (title: string): string => {
    let projectFont = "project__title";
    switch (title) {
      case "Broken Bottts":
        projectFont += "--broken-bottts";
        break;
      case "INSTOCK":
        projectFont += "--instock";
        break;
      case "BrainFlix":
        projectFont += "--brainflix";
        break;
      case "Industry Hackathon":
        projectFont += "--industry-hackathon";
        break;
      case "Pokemon Drink Battle!":
        projectFont += "--first-hackathon";
        break;
      case "puddl":
        projectFont += "--puddl";
        break;
      case "Members Only":
        projectFont += "--members-only";
        break;
      case "Blog":
        projectFont += "--blog";
        break;
      case "Nick's Bread's":
        projectFont += "--bread";
        break;
      default:
        projectFont = "";
        break;
    }
    return projectFont;
  };

  useEffect(() => {
    setLoadingImg(0);
  }, [showImages]);

  const [loadingImg, setLoadingImg] = useState(0);

  return (
    <section className="project">
      <div className="project__header">
        <div className="project__title-wrapper">
          <h3 className={`project__title ${titleFont(name)}`}>{name}</h3>
          <div className="project__technologies project__technologies--mobile">
            {tech.map((technology, i) => (
              <span key={i}>
                {technology} {i !== tech.length - 1 ? "•" : ""}{" "}
              </span>
            ))}
          </div>
        </div>
        <p className="project__date">{date}</p>
      </div>
      <p className="project__description">{description}</p>
      <ul
        className={`project__details ${
          imgs ? "" : "project__details--no-imgs"
        }`}
      >
        {details.map((detail, i) => (
          <div key={i} className="project__detail-wrapper">
            <BiRightArrow className="project__detail-icon" />
            <li className="project__detail">{detail}</li>
          </div>
        ))}
      </ul>
      {(imgs || mobileImgs) && (
        <Button
          additionalClasses={!darkMode ? "btn--light" : ""}
          text={showImages === name ? "Hide Images" : "Show Images"}
          handleInput={() => {
            if (showImages === name) {
              setShowImages("NA");
              return;
            }
            setShowImages(name);
          }}
        />
      )}
      {imgs && showImages === name && (
        <div className="project__imgs-wrapper">
          {imgs.map((img, i) => (
            <div className="project__img-wrapper" key={i}>
              {loadingImg < imgs.length && (
                <LoadingScreen
                  additionalClassScreen={
                    darkMode ? "" : "loading-wrapper--light"
                  }
                  additionalClassRoller={darkMode ? "" : "lds-roller--light"}
                />
              )}
              <img
                className={`project__img ${
                  !darkMode ? "project__img--light" : ""
                }`}
                src={img}
                alt="application demo"
                loading="lazy"
                onLoad={() => setLoadingImg(loadingImg + 1)}
              />
            </div>
          ))}
        </div>
      )}
      {mobileImgs && showImages === name && (
        <div className="project__imgs-wrapper project__imgs-wrapper--mobile">
          {mobileImgs.map((img, i) => (
            <div
              className="project__img-wrapper project__img-wrapper--mobile"
              key={i}
            >
              {loadingImg < mobileImgs.length && (
                <LoadingScreen
                  additionalClassScreen={
                    darkMode
                      ? "loading-wrapper--mobile"
                      : "loading-wrapper--mobile loading-wrapper--light"
                  }
                  additionalClassRoller={
                    darkMode
                      ? "lds-roller--mobile"
                      : "lds-roller--mobile lds-roller--light"
                  }
                />
              )}
              <img
                className={`project__img project__img--mobile ${
                  !darkMode
                    ? "project__img project__img--mobile project__img--light"
                    : ""
                }`}
                src={img}
                alt="mobile application demo"
                loading="lazy"
                onLoad={() => setLoadingImg(loadingImg + 1)}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Project;
