import brokenBotttsGameplay from "../assets/images/brokenBotttsGameplay.gif";
import brokenBotttsBuilder from "../assets/images/brokenBotttsBuilder.gif";
import inStockGif from "../assets/images/InStock.gif";
import brainFlixIntro from "../assets/images/brainFlixIntro.gif";
import brainFlixUpload from "../assets/images/brainFlixUpload.gif";
import pokemonBattleGif from "../assets/images/pokemonBattle.gif";
import pokemonSelectionGif from "../assets/images/pokemonSelection.gif";
import puddlChatGif from "../assets/images/puddlChat.gif";
import puddlCharacter from "../assets/images/puddlCharacter.jpg";
import blogUser from "../assets/images/blogUser.gif";
import blogAdmin from "../assets/images/blogAdmin.gif";
import membersOnlyImg from "../assets/images/membersOnly.png";

export interface ProjectInterface {
  name: string;
  date: string;
  description: string;
  details: string[];
  tech: string[];
  imgs?: string[];
  mobileImgs?: string[];
}

const brokenBottts: ProjectInterface = {
  name: "Broken Bottts",
  date: "Apr 2022",
  description:
    "A steampunk twist on the popular game Where's Waldo. Users must find the correct robot over 4 levels. Levels get progressively harder as there is an increase in robots and simmilarities.",
  details: [
    "Architechted a caching system that stores pre-processed game assets to reduce processing load on the client. MongoDB was used to persist the cache.",
    "Built a shareable level generation system, allowing players to create/share seeded games and compete for the best time.",
    "Utilized Material-UI for common components and used their theming system to override the default styling to match the applications design.",
    "Learned to utilize custom hooks.",
  ],
  tech: ["React", "NodeJs", "MongoDB"],
  imgs: [brokenBotttsGameplay, brokenBotttsBuilder],
};

const industryHackathon: ProjectInterface = {
  name: "Industry Hackathon",
  date: "Mar 2022",
  description: "A hackthon event hosted by BrainStation and Shopify.",
  details: [
    "Collaborated with multiple disciplines including UI/UX designers and data scientists to solve the challenge Shopify presented us.",
    "Worked especially close with the UI/UX team in order to create feasible application designs in the time given.",
    "Led the web development side of the project. Delegated tasks and responsibilities.",
    "Our team qualified as runners up among 32 other teams and over 200 participants.",
  ],
  tech: ["React"],
};

const inStock: ProjectInterface = {
  name: "INSTOCK",
  date: "Mar 2022",
  description: "A warehouse management application",
  details: [
    "Designed and implemented a RESTful API to allow users to manage warehouse and inventory data.",
    "Utilized MySQL to handle warehouse and inventory data/relationships.",
    "Led the project in a team setting by implementing agile methodology, managing tickets and assigning tasks.",
    "Utilized Git and Jira to manage multiple developer work flows and GitHub to review and merge pull requests",
    "Built and styled responsive components and layouts to match Figma designs across all devices.",
  ],
  tech: ["React", "NodeJs", "MySQL"],
  imgs: [inStockGif],
};

const brainflix: ProjectInterface = {
  name: "BrainFlix",
  date: "Feb 2022",
  description: "A YouTube clone",
  details: [
    "Designed and implemented a RESTful API that allow users to dynamically render the video list and videos, as well as to leave comments/likes.",
    "Implemented a feature to allow for image uploads to the server for custom video thumbnails.",
    "Implemented a new custom video player using Material-UI.",
    "Built and styled responsive components and layouts to match Figma designs across all devices.",
  ],
  tech: ["React", "NodeJs"],
  imgs: [brainFlixIntro, brainFlixUpload],
};

const pokemonDrinkingBattle: ProjectInterface = {
  name: "Pokemon Drink Battle!",
  date: "Feb 2022",
  description:
    "First BrainStation hackathon. Built alongside two other developers, this game is a lighthearted pokemon battle simulator with alcholoic beverages replacing standard move sets.",
  details: [
    "I handled promises for the various APIs, the pokemon selection logic for live search, and was involved in the pokemon battle mechanics.",
    "The pokemon API is used to provide a wide selection of pokemon to choose from and to determine their attack power in battle.",
    "Caches the initial request for all pokemon to speed up queries when filtering through pokemon.",
    "Drink moves are based on the cocktail API list and the pokemon's name.",
  ],
  tech: ["JS", "HTML/CSS"],
  imgs: [pokemonSelectionGif, pokemonBattleGif],
};

const puddl: ProjectInterface = {
  name: "puddl",
  date: "Dec 2021",
  description: "A chat application for friends.",
  details: [
    "Designed and implemented a sticker feature that allowed users to send automatically generated facial expressions based off their avatar.",
    "Built an avatar creator/editor for both web and mobile that allowed for thousands of different combinations.",
    "Collaborated with the team on how to manage avatar create/edit functionality on the back-end.",
    "Dealt with issues regarding different renderings for IOS/Android.",
  ],
  tech: ["React/Native", "Typescript", "Firebase"],
  mobileImgs: [puddlCharacter, puddlChatGif],
};

const membersOnly: ProjectInterface = {
  name: "Members Only",
  date: "Nov 2021",
  description:
    "A public forum where anyone can message. Certain account types have varrying permissions.",
  details: [
    "Uses session based authentication to keep the user logged in.",
    "Sanatizes all inputs from the user using express validator middleware.",
    "Account types which can be verified to be a member or admin gain additional privileges such as reading and deleting certain messages/details.",
    "Built to be responsive.",
  ],
  tech: ["PUG", "NodeJs", "MongoDB"],
  imgs: [membersOnlyImg],
};

const blogProject: ProjectInterface = {
  name: "Blog",
  date: "Dec 2021",
  description:
    "Built with two seperate front-ends, admins and users can interact with blog posts differently through their respective domains.",
  details: [
    "Created a RESTful api that handles requests from two seperate domains and only permits these specific front-end applications to make requests.",
    "Sanatizes all inputs from the user using express validator middleware.",
    "Users can view and comment on different blogs.",
    "Admins can create, edit, and delete blogs. In addition, they can temporarily hide or reveal them to general users.",
    "Built to be responsive.",
  ],
  tech: ["React", "NodeJs", "MongoDB"],
  mobileImgs: [blogAdmin, blogUser],
};

// const nicksBreads: ProjectInterface = {
//   name: "Nick's Bread's",
//   date: "Nov 2021",
//   description:
//     "For all your bread related needs. A small bread shop meant to test ",
//   details: [
//     "Created a RESTful api that handles requests to perform CRUD operations on bread brands and the individual breads they carry.",
//     "Sanatizes all inputs from the user using express validator middleware.",
//     "Can checkout item to a global shopping cart.",
//     "Built to be responsive.",
//   ],
//   tech: ["PUG", "NodeJs", "MongoDB"],
//   mobileImgs: [blogAdmin, blogUser],
// };

const projectsInfo: ProjectInterface[] = [
  brokenBottts,
  industryHackathon,
  inStock,
  brainflix,
  pokemonDrinkingBattle,
  puddl,
  blogProject,
  membersOnly,
];

export default projectsInfo;
