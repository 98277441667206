import Particles from "react-tsparticles";

interface ParticlesInterface {
  darkMode: boolean;
}

const ParticlesBackground = ({ darkMode }: ParticlesInterface) => {
  return (
    <Particles
      options={{
        fpsLimit: 60,
        particles: {
          line_linked: {
            enable: true,
            distance: 150,
            color: `${darkMode ? "#9225ff" : "#0071a6"}`,
            opacity: darkMode ? 0.5 : 0.5,
            width: 1,
          },
          number: {
            value: 30,
            limit: 100,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          shape: {
            type: "circle",
            stroke: {
              width: 3,
              color: `${darkMode ? "#9225ff" : "#05aaf6"}`,
            },

            image: {
              src: "images/github.svg",
              width: 5,
              height: 5,
            },
          },
          opacity: {
            value: 0.1,
            random: false,
            // anim: {
            //   enable: true,
            //   speed: 2,
            //   opacity_min: 0.1,
            //   sync: false,
            // },
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: true,
          },
        },
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "connect",
            },
          },
        },
      }}
    />
  );
};

export default ParticlesBackground;
