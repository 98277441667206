import "./ProjectsSection.scss";
import projectsInfo from "../../assets/projectInfo";
import Project from "../Project/Project";
import brainstationLightIcon from "../../assets/images/brainstationLightIcon.png";
import brainstationDarkIcon from "../../assets/images/brainstationDarkIcon.png";
import odinIcon from "../../assets/images/odinIcon.svg";
import mcmasterIcon from "../../assets/images/mcmaster.svg";
import { useState, useRef } from "react";
import Button from "../Button/Button";

interface ProjectsSectionInterface {
  darkMode: boolean;
}

const ProjectsSection = ({ darkMode }: ProjectsSectionInterface) => {
  const projectsSection = useRef<HTMLElement>(null);
  const [showImages, setShowImages] = useState("None");

  return (
    <section ref={projectsSection} className="projects-section">
      <section className="education">
        <h2
          className={`education__heading ${
            !darkMode ? "education__heading--light" : ""
          }`}
        >
          Education
        </h2>
        <div className="education__item education__item--brainstation">
          <div className="education__title-logo-wrapper">
            <img
              src={`${darkMode ? brainstationDarkIcon : brainstationLightIcon}`}
              alt="Education Logo"
              className="education__logo"
            />
            <a
              href="https://brainstation.io/course/online/remote-web-development-bootcamp"
              rel="noreferrer"
              target="_blank"
              className={`education__link ${
                darkMode ? "" : "education__link--light"
              }`}
            >
              <h3 className="education__title education__title--brainstation">
                BrainStation
              </h3>
            </a>
          </div>
          <p className="education__date">Jan 2022 - Apr 2022</p>
        </div>
        <p>
          An industry leading coding bootcamp. BrainStation teaches the core
          fundamentals behind web development through a full-stack JavaScript
          curriculum. Topics covered include HTML/CSS, JS, SASS, React, NodeJs,
          BEM and Agile methodology, and MySQL.
        </p>
        <div className="education__item">
          <div className="education__title-logo-wrapper">
            <img
              src={odinIcon}
              alt="Education Logo"
              className="education__logo"
            />
            <a
              href="https://www.theodinproject.com"
              rel="noreferrer"
              target="_blank"
              className={`education__link ${
                darkMode ? "" : "education__link--light"
              }`}
            >
              <h3 className="education__title education__title--odin-project">
                The Odin Project
              </h3>
            </a>
          </div>
          <p className="education__date">Apr 2021 - Dec 2021</p>
        </div>
        <p>
          One of the most popular open-sourced web development curriculums
          available and updated almost daily by a driven community. The Odin
          Project is taught through project-based learning and covers a breadth
          of concepts including HTML/CSS, JS, React, NodeJs, and MongoDB.
        </p>
        <div className="education__item">
          <div className="education__title-logo-wrapper">
            <img
              src={mcmasterIcon}
              alt="Education Logo"
              className="education__logo"
            />
            <h3 className="education__title education__title--mcmaster">
              McMaster University
            </h3>
          </div>
          <p className="education__date">Sep 2016 - Apr 2020</p>
        </div>
        <p className="education__paragraph">
          <span>Honours B.Commerce, Accounting</span>{" "}
          <span>Cumulative GPA - 3.7 / 4.0</span>
        </p>
      </section>
      <h2
        className={`projects-section__title ${
          !darkMode ? "projects-section__title--light" : ""
        }`}
      >
        Projects
      </h2>
      {projectsInfo.map((proj, i) => (
        <Project
          key={i}
          {...proj}
          darkMode={darkMode}
          setShowImages={setShowImages}
          showImages={showImages}
        />
      ))}
      <Button
        text="Scroll To Top"
        handleInput={() => {
          projectsSection.current?.scrollTo(0, 0);
          setShowImages("None");
        }}
        additionalClasses={
          !darkMode ? "btn--light btn--desktop" : "btn--desktop"
        }
      />
      <Button
        text="Scroll To Top"
        handleInput={() => {
          window.scrollTo(0, 0);
          setShowImages("None");
        }}
        additionalClasses={!darkMode ? "btn--light btn--mobile" : "btn--mobile"}
      />
    </section>
  );
};

export default ProjectsSection;
