import "./App.scss";
import { useState } from "react";
import Header from "./Components/Header/Header";
import ProjectsSection from "./Components/ProjectsSection/ProjectsSection";
import ParticlesBackground from "./Components/Particles/Particles";

const App = () => {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div
      className={`app-wrapper ${
        darkMode ? "app-wrapper--dark" : "app-wrapper--light"
      }`}
    >
      <ParticlesBackground darkMode={darkMode} />
      <div className="app">
        <Header darkMode={darkMode} setDarkMode={setDarkMode} />
        <ProjectsSection darkMode={darkMode} />
      </div>
    </div>
  );
};

export default App;
