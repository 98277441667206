import "./Button.scss";

interface btn {
  additionalClasses?: string;
  text: string;
  handleInput: () => void;
}

const Button = ({ additionalClasses, text, handleInput }: btn) => {
  return (
    <button onClick={handleInput} className={`btn ${additionalClasses}`}>
      {text}
    </button>
  );
};

export default Button;
