import "./LoadingScreen.scss";

interface LoadingScreenInterface {
  additionalClassScreen?: string;
  additionalClassRoller?: string;
}

const LoadingScreen = ({
  additionalClassScreen,
  additionalClassRoller,
}: LoadingScreenInterface) => {
  return (
    <div
      className={`loading-wrapper ${
        additionalClassScreen ? additionalClassScreen : ""
      }`}
    >
      <div
        className={`lds-roller ${
          additionalClassRoller ? additionalClassRoller : ""
        }`}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
