import "./Header.scss";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import ThemeSwitch from "../Switch/Switch";

interface HeaderInterface {
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode: boolean;
}

const Header = ({ darkMode, setDarkMode }: HeaderInterface) => {
  interface social {
    icon: any;
    link: string;
    name: string;
  }

  let socials: social[] = [
    {
      icon: <FaGithub className="header__contact-icon" />,
      link: "https://github.com/NickCascella",
      name: "NickCascella",
    },
    {
      icon: <FaLinkedin className="header__contact-icon" />,
      link: "https://www.linkedin.com/in/nick-cascella",
      name: "NickCascella",
    },
    {
      icon: <FcGoogle className="header__contact-icon" />,
      link: "mailto:njcascella@gmail.com",
      name: "njcascella@gmail.com",
    },
  ];

  return (
    <div className="header-wrapper">
      <header className="header">
        <div
          className={`header__name-btn-wrapper ${
            !darkMode ? "header__name-btn-wrapper--light" : ""
          }`}
        >
          <h1
            className={`header__name ${!darkMode ? "header__name--light" : ""}`}
          >
            Nick Cascella
          </h1>
          <span className="switch--top">
            <ThemeSwitch
              onChange={() => {
                setDarkMode(!darkMode);
              }}
              checked={!darkMode ? true : false}
            />
          </span>
        </div>

        <h2 className="header__title">Software Developer</h2>
        <div className="header__contact-details">
          {socials.map((social, i) => (
            <a
              key={i}
              rel="noreferrer"
              className={`header__contact-detail ${
                darkMode
                  ? "header__contact-detail--dark"
                  : "header__contact-detail--light"
              }`}
              href={social.link}
              target="_blank"
            >
              {social.icon} {social.name}
            </a>
          ))}
        </div>
        <p>
          I am someone who loves to solve problems, learn new technologies, and
          collaborate with others to build some amazing things. Oh, also
          basketball and food. Feel free to connect to chat about anything and
          everything.
        </p>
        <span className="switch--bottom">
          <ThemeSwitch
            onChange={() => {
              setDarkMode(!darkMode);
            }}
            checked={!darkMode ? true : false}
          />
        </span>
      </header>
    </div>
  );
};

export default Header;
